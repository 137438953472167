import { mapValues, omit, toPairs, assign } from "lodash";
import {
  AssetConfig,
  DecoratedAsset,
  DecoratedProperty,
  Field,
  FieldConfig,
  FieldDescriptor,
  FieldUpdateStatus,
  Form,
  FormConfig,
  PropertyConfig,
  PropertyState
} from "@/types";
import { getProperty } from "./asset";
import { getPropertyConfig } from "@/utils/properties";
import { setField, readFieldConfig, expandFieldConfig } from "@/config/form";
import { expandDescriptorToIndexes } from "@/utils/indexed-field";
import i18n from "@/plugins/i18n";
import features from "./features";

const PROPERTY_STATE_TO_UPDATE_STATUS: Record<PropertyState, FieldUpdateStatus | null> = {
  DOWNLINKED: null,
  DOWNLINK_CONFIRMED: "success",
  UPLINK_MATCHED: "success",
  CONFIRM_TIMEOUT: "failure",
  UPLINK_TIMEOUT: "failure",
  UPLINK_MISMATCH: "failure",
  REFRESHING: null,
  ERROR: "failure"
};

export function buildAssetFormConfig(assetConfig: AssetConfig): FormConfig {
  const { i18nNamespace, properties, fields } = assetConfig;

  const propertyFieldConfigs: Record<string, FieldConfig> = {};
  toPairs(properties).forEach(([name, config]) => {
    const fieldConfigs = buildFieldConfigs(name, config);
    assign(propertyFieldConfigs, fieldConfigs);
  });

  const fieldConfigs = mapValues(fields, config => ({
    ...config
  }));

  return {
    i18nNamespace,
    fields: { ...propertyFieldConfigs, ...fieldConfigs }
  };
}

function buildFieldConfigs(name: string, propertyConfig: PropertyConfig): Record<string, FieldConfig> {
  const fieldConfig = convertPropertyConfigToFieldConfig(propertyConfig, propertyConfig.fieldConfig);
  return expandFieldConfig(name, fieldConfig);
}

function convertPropertyConfigToFieldConfig(
  propertyConfig: PropertyConfig,
  fieldConfig: Partial<FieldConfig>
): FieldConfig {
  const strippedConfig = omit(propertyConfig, ["displayType"]);
  return readFieldConfig({
    ...strippedConfig,
    ...fieldConfig,
    managedState: true
  });
}

export function copyPropertyToForm(
  asset: DecoratedAsset,
  form: Form,
  descriptor: FieldDescriptor,
  force = false
): void {
  const propertyConfig = getPropertyConfig(asset.config, descriptor);
  const descriptors = expandDescriptorToIndexes(asset.properties, descriptor, propertyConfig.dimensions);

  for (const expansion of descriptors) {
    const property = getProperty(asset, expansion);
    copySinglePropertyToForm(property, form, force);
  }
}

export function copySinglePropertyToForm(property: DecoratedProperty, form: Form, force = false): void {
  const status = fieldUpdateStatus(property);

  let value: any;
  let originalValue: any;
  let newValue: any;

  const useNewValue = property.pending && property.state !== "REFRESHING";
  if (useNewValue) {
    newValue = value = property.state_info.new_value;
    originalValue = property.state_info.old_value;
  } else {
    value = property.value;
  }

  const field: Partial<Field> = {
    originalValue,
    value,
    timestamp: property.timestamp,
    updateInfo: {
      status,
      message: fieldUpdateMessage(property, status),
      newValue
    }
  };

  setField(form.config, form, property, field, force);
}

function fieldUpdateStatus(property: DecoratedProperty): FieldUpdateStatus {
  const { state: propertyState, pending } = property;
  if (pending) return "pending";
  if (!features.stateManagement || !propertyState) return "normal";
  return PROPERTY_STATE_TO_UPDATE_STATUS[propertyState] ?? "normal";
}

function fieldUpdateMessage(property: DecoratedProperty, status: FieldUpdateStatus): string | undefined {
  if (status === "normal") return undefined;

  const propertyState = property.state ?? "UNKNOWN";
  const translationKey = `state_management.status.${propertyState}`;
  if (!i18n.te(translationKey)) {
    return i18n.t("state_management.status.UNRECOGNIZED", { status: propertyState }).toString();
  }

  return i18n.t(translationKey).toString();
}
