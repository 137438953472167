import { mapValues } from "lodash";

const defaultFlags: Record<string, boolean> = {
  local: true
};

// By default, features are only enabled in the local environment.
// Enable them here using the Capistrano stage names.
const featureFlags: Record<string, Record<string, boolean>> = {
  assetHistory: {
    test: true,
    development: true,
    staging: true,
    production: true
  },
  alerting: {
    test: true,
    development: true,
    staging: true,
    production: true
  },
  buildingControls: {
    test: true,
    development: true,
    staging: true,
    production: true
  },
  enableVsFlowOffset: {
    test: true,
    development: true,
    staging: true
  },
  vsScheduling: {
    test: true,
    development: true,
    staging: true,
    production: true
  },
  stateManagement: {
    test: true,
    development: true,
    staging: true,
    production: true
  },
  thermostatSchedules: {
    test: true,
    development: true,
    staging: true,
    production: true
  }
};

const environment = process.env.VUE_APP_ENVIRONMENT ?? "local";
const features = mapValues(featureFlags, environments => {
  const environmentsWithDefaults = { ...defaultFlags, ...environments };
  return environmentsWithDefaults[environment] ?? false;
});

export default features;
