import { NavigationContext } from "@/types";

const state = {
  breadcrumbs: [],
  navContext: {}
};

const getters = {
  // eslint-disable-next-line
  breadcrumbs(state: Record<any, any>): Record<string, any>[] {
    return state.breadcrumbs;
  },
  // eslint-disable-next-line
  navContext(state: Record<any, any>): NavigationContext {
    return state.navContext;
  },
  contextAccountUuid(state: Record<any, any>, getters: Record<any, any>, rootState: Record<any, any>): string | null {
    const { asset } = state.navContext as NavigationContext;
    return asset?.accountUuid ?? rootState.auth.accountUuid ?? null;
  }
};

const mutations = {
  setBreadcrumbs(state: Record<any, any>, breadcrumbs: Record<string, any>[]): void {
    state.breadcrumbs = breadcrumbs;
  },
  setNavContext(state: Record<any, any>, context: NavigationContext = {}): void {
    state.navContext = context;
  }
};

export default {
  state,
  getters,
  mutations
};
