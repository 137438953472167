import { ref, Ref, unref } from "vue";
import { useLazyQuery } from "@vue/apollo-composable";
import gql from "graphql-tag";
import { Document, MaybeRef } from "@/types";
import store from "@/store";

export interface UseDocumentsResult {
  documents: Ref<Document[]>;
  loadingError: Ref<boolean>;
  loadDocuments: () => Promise<any> | undefined;
  loading: Ref<boolean>;
}

export function useDocuments(
  locationType: MaybeRef<string>,
  locationUuid: MaybeRef<string>,
  accountUuid: MaybeRef<string | null> = null
): UseDocumentsResult {
  const documents: Ref<Document[]> = ref([]);
  const loadingError = ref(false);

  const { onResult, onError, load, refetch, loading } = useLazyQuery(
    gql`
      query ($accountUuid: ID!, $locationType: LocationType!, $locationUuid: ID!) {
        documents(accountUuid: $accountUuid, locationType: $locationType, locationId: $locationUuid) {
          uuid
          name
          ownerName
          uploadDate
          locationId
          locationType
          modificationDate
          mimeType
          originalExtension
          url
          thumbnailUrl
          priority
        }
      }
    `,
    {
      accountUuid: unref(accountUuid) ?? store.getters.contextAccountUuid,
      locationUuid: unref(locationUuid),
      locationType: unref(locationType)
    },
    {
      fetchPolicy: "no-cache",
      notifyOnNetworkStatusChange: true
    }
  );

  onError(() => {
    loadingError.value = true;
  });

  onResult(queryResult => {
    if (queryResult.data) {
      documents.value = queryResult.data.documents;
    }
  });

  const loadDocuments = (): Promise<any> | undefined => {
    return (
      load() ||
      refetch({
        accountUuid: unref(accountUuid) ?? store.getters.contextAccountUuid,
        locationUuid: unref(locationUuid),
        locationType: unref(locationType)
      })
    );
  };

  return { documents, loadingError, loadDocuments, loading };
}
