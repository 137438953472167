import { unref } from "vue";
import gql from "graphql-tag";
import { apolloClient } from "@/plugins/vue-apollo";
import { DocumentUploadDetails } from "@/types";
import store from "@/store";
import { MaybeRef } from "@vueuse/core";

export default function (files: DocumentUploadDetails[], accountUuid: MaybeRef<string | null> = null): Promise<any> {
  return apolloClient
    .mutate({
      mutation: gql`
        mutation ($accountUuid: ID!, $detailsList: [DocumentUploadDetails!]!) {
          newDocuments(accountUuid: $accountUuid, detailsList: $detailsList) {
            message
            stamp
            statusCode
          }
        }
      `,
      variables: {
        accountUuid: unref(accountUuid) ?? store.getters.contextAccountUuid,
        detailsList: files
      },
      context: {
        hasUpload: true
      }
    })
    .then(response => {
      return response.data.newDocuments;
    });
}
